import { h, Component } from 'preact';
import { Link, Match } from 'preact-router/match';
import firebase from '../firebase';

import GymIcon from '../../components/SVG/Icons/gym.js';
import ProfileIcon from '../../components/SVG/Icons/profile.js';
import AdminIcon from '../../components/SVG/Icons/admin.js';
import AnalyticsIcon from '../../components/SVG/Icons/analytics.js';
import BankIcon from '../../components/SVG/Icons/bank.js';

import style from './style';

export default class NavBar extends Component {
	render({ rootPath, user, isAdmin }) {
		return (
			<div>
				<div class={style.navbar}>
					<nav>
						<Link
							activeClassName={style.active}
							class={style.nav_item}
							href={rootPath}
						>
							<GymIcon/>
							<span>Classes</span>
						</Link>
						{isAdmin && <Match path="/admin">
							{({ path, url }) => (
								<Link
									activeClassName={style.active}
									class={style.nav_item}
									href={rootPath + 'admin'}
									path={path.startsWith(`${rootPath}admin/`) ? url : undefined}
								>
									<AdminIcon/>
									<span>Admin</span>
								</Link>
							)}
						</Match>}
						{isAdmin && <Match path="/payment">
							{({ path, url }) => (
								<Link
									activeClassName={style.active}
									class={style.nav_item}
									href={rootPath + 'payment'}
									path={path.startsWith(`${rootPath}payment/`) ? url : undefined}
								>
									<BankIcon/>
									<span>Payment</span>
								</Link>
							)}
						</Match>}
						<Match path="/profile">
							{({ path, url }) => (
								<Link
									activeClassName={style.active}
									class={style.nav_item}
									href={rootPath + 'profile'}
									path={path.startsWith(`${rootPath}profile/`) ? url : undefined}
								>
									<ProfileIcon/>
									<span>Profile</span>
								</Link>
							)}
						</Match>
						<div class={style.line} />
					</nav>
				</div>
			</div>
		);
	}
}
