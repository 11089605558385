import { firebase } from "@firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/storage";
import "firebase/functions";

var firebaseConfig = {
  apiKey: "AIzaSyBGgPzq7fkJEa_D-ch-jC_fu2Aonyk5_g0",
  authDomain: "fitness4her-dev.firebaseapp.com",
  databaseURL: "https://fitness4her-dev-default-rtdb.firebaseio.com",
  projectId: "fitness4her-dev",
  storageBucket: "fitness4her-dev.appspot.com",
  messagingSenderId: "392337845437",
  appId: "1:392337845437:web:85b6d37cfd43493ec21bd4",
  measurementId: "G-GB7VV9R32S"
};

firebase.initializeApp(firebaseConfig);
if (typeof window !== "undefined") {
  import("firebase/performance").then(() => firebase.performance());
}
export default firebase;
