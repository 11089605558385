import { h, Component } from 'preact';
import { Router,route } from 'preact-router';
import firebase from './firebase';

import NavBar from './navbar';
import Home from '../routes/home';
import Admin from '../routes/admin';
import Login from '../routes/login';
import Profile from '../routes/profile';
import Payment from '../routes/payment';
import PageNotFound from '../routes/404';

import { snackbarQueue, dialogQueue } from './queue';
import { SnackbarQueue } from '@rmwc/snackbar';
import '@rmwc/snackbar/styles';
import { DialogQueue } from '@rmwc/dialog';
import '@rmwc/dialog/styles';
import { ThemeProvider } from '@rmwc/theme';
import { Portal } from '@rmwc/base';

import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";


export default class App extends Component {

	state = {
		currentUser: null,
		rootPath: '/',
		userPermissions:[],
		userClaims:{}
	};

	handleRoute = e => {
		this.currentUrl = e.url;
	};

	componentWillMount() {
		if (typeof window !== 'undefined') {
			Sentry.init({
				dsn: "https://cb5760a0f4bc447ab5a9a47da0db5a1d@o411904.ingest.sentry.io/5655036",
				integrations: [new Integrations.BrowserTracing()],
				tracesSampleRate: 1.0,
			  });
			this.db = firebase.firestore();
			this.rtdb = firebase.database();
			this.setState({ userState: 'logging_in' });
			firebase.auth().onAuthStateChanged(currentUser => {
				this.setState({userState: 'completed_login', currentUser:currentUser });
				if (currentUser) {
					if(this.currentUrl.startsWith(this.state.rootPath + 'login')){
						route(this.state.rootPath)
					}
					this.getNewToken(currentUser);
					this.detachCurrentUser = this.db.doc(`users/${currentUser.uid}`).onSnapshot(snap=>{
						this.setState({userFirestore:snap.data()})
					})
					this.rtdb.ref(`refreshtoken/${currentUser.uid}`).on('value',snap=>{
						if(snap.val()>0){
							console.log('Updating user claims')
							this.rtdb.ref(`refreshtoken/${currentUser.uid}`).set(0);
							this.getNewToken(currentUser);
						}
					})
				}
				else {
					this.setState({userFirestore:{}});
					if(this.detachCurrentUser)this.detachCurrentUser();
					route(`${this.state.rootPath}login`)
				}
			});
		}
	}

	getNewToken(currentUser){
		currentUser.getIdTokenResult(true).then(idTokenResult=>{
			this.setState({isAdmin:idTokenResult.claims.isAdmin});
		})
	}

	showRefreshSnack = () => {
		snackbarQueue.notify({
			Title: 'Site updated. Refresh this page for better experience.',
		});
	};

	componentDidMount() {
		window.addEventListener('showRefreshSnack', this.showRefreshSnack);
	}

	componentWillUnmount() {
		window.removeEventListener('showRefreshSnack', this.showRefreshSnack);
	}

	render({},{
		rootPath,
		userState,
		currentUser,
		isAdmin,
		userFirestore}) {
		return (
			<ThemeProvider id="app" options={{
				primary: '#303030',
				secondary: '#661fff',
				error: '#b00020',
				background: '#fff',
				surface: '#fff',
				onPrimary: 'rgba(255, 255, 255, 1)',
				onSecondary: 'rgba(255, 255, 255, 1)',
				onSurface: 'rgba(0, 0, 0, 0.87)',
				onError: '#fff',
				textPrimaryOnBackground: 'rgba(0, 0, 0, 0.87)',
				textSecondaryOnBackground: 'rgba(0, 0, 0, 0.54)',
				textHintOnBackground: 'rgba(0, 0, 0, 0.38)',
				textDisabledOnBackground: 'rgba(0, 0, 0, 0.38)',
				textIconOnBackground: 'rgba(0, 0, 0, 0.38)',
				textPrimaryOnLight: 'rgba(0, 0, 0, 0.87)',
				textSecondaryOnLight: 'rgba(0, 0, 0, 0.54)',
				textHintOnLight: 'rgba(0, 0, 0, 0.38)',
				textDisabledOnLight: 'rgba(0, 0, 0, 0.38)',
				textIconOnLight: 'rgba(0, 0, 0, 0.38)',
				textPrimaryOnDark: 'white',
				textSecondaryOnDark: 'rgba(255, 255, 255, 0.7)',
				textHintOnDark: 'rgba(255, 255, 255, 0.5)',
				textDisabledOnDark: 'rgba(255, 255, 255, 0.5)',
				textIconOnDark: 'rgba(255, 255, 255, 0.5)'
			  }}>
				<Router onChange={this.handleRoute}>
					<Home
						path={rootPath}
						user={currentUser}
						rootPath={rootPath}
						userFirestore={userFirestore}
					/>
					{isAdmin && <Admin
						path={rootPath + 'admin'}
						user={currentUser}
						isAdmin={isAdmin}
						rootPath={rootPath}
					/>}
					{isAdmin && <Payment
						path={rootPath + 'payment'}
						user={currentUser}
						isAdmin={isAdmin}
						rootPath={rootPath}
					/>}
					<Login
						path={rootPath + 'login'}
						user={currentUser}
						rootPath={rootPath}
					/>
					<Profile
						path={rootPath + 'profile'}
						user={currentUser}
						userFirestore={userFirestore}
					/>
					<PageNotFound path={rootPath} default/>
				</Router>
				{currentUser && !(Object.keys(currentUser).length === 0 && currentUser.constructor === Object) &&<NavBar
					rootPath={rootPath}
					user={currentUser}
					userState={userState}
					isAdmin={isAdmin}
				/>}
				<DialogQueue dialogs={dialogQueue.dialogs} />
				<Portal/>
				<SnackbarQueue messages={snackbarQueue.messages}/>
			</ThemeProvider >
		);
	}
}
